import * as React from "react"
import { graphql } from "gatsby"

import TrustedBrands from '../Elements/TrustedBrands';

import PricingTables from './PricingTables';

const HeroSection = ({ slice }) => {
  const { primary } = slice
  const { heading, sub_heading } = primary
  return (
    <div className="background1">
      <div className="sm:pt-48 pt-28 sm:pb-16 pb-8 relative">
        <div className="bg2 absolute top-0 right-0"></div>
        <div className="max-w-4xl mx-auto px-4 py-10">
          <div className="md:text-center text-left">
            <h1 className="lg:text-7xl md:text-6xl text-4xl md:mb-9 mb-5 text-blue font-medium">{heading && heading.text}</h1>
            <p className="md:text-xl text-base md:mx-auto md:mb-10 text-blue font-normal">{sub_heading}</p>
          </div>
        </div>
        <PricingTables/>
      </div>
      <TrustedBrands theme="light" className="pt-16 pb-28" />
    </div>
  )
}

export default HeroSection

export const query = graphql`
  fragment pricingHeroSlice on PrismicPricingPageDataBodyHero {
    id
    slice_type
    primary {
      heading {
        text
        html
      }
      sub_heading
    }
  }
`
