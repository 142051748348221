import React from "react";
import { graphql } from "gatsby";
import { SliceZone } from "@prismicio/react"

import { components } from "../components/Pricing"
import SEO from "../components/seo"
import Layout from "../components/layout"
const PricingPage = (props) => {
  const { data } = props
  if (!data) return null  
  const pageData = data.prismicPricingPage
  const page = pageData.data || {}
  return (
    <Layout theme={props.pageContext.header_theme && props.pageContext.header_theme}>    
      <SEO
        context={props.pageContext}
        cpath={props.location.pathname}
      />
      <SliceZone
        slices={page.body}
        components={components}
        context={pageData}
        defaultComponent={() => null}
      />  
    </Layout>
  );
};

export default PricingPage

export const query = graphql`
  query pricingPageQuery($uid: String!){
    prismicPricingPage(uid: {eq: $uid}) {
        uid
        id
        type
        data{
            body{
                ... on PrismicSliceType {
                  slice_type
                }
                ...pricingHeroSlice
                ...pricingTestimonialSlice
                ...pricingFaqSlice
            }
        }
    }
  }
`
