import * as React from "react"
import { graphql } from "gatsby"
import FaqSection from '../Elements/FaqSection';

export default (props) => (
  <FaqSection {...props} className="background1" />
);

export const query = graphql`
  fragment pricingFaqSlice on PrismicPricingPageDataBodyFaqs{
    id
    slice_type
    items {
      faq {
        document {
          ... on PrismicFaq {
            id
            data {
              question {
                text
                html
              }
              answer {
                text
                html
                richText
              }
            }
          }
        }
      }
    }
  }
`
