import * as React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import { Disclosure } from '@headlessui/react'
import { ArrowDownIcon } from '@heroicons/react/outline'

import { Button2 } from "./Buttons"

const classNames = (...names) => names.filter(Boolean).join(' ');

const FaqSection = ({ className, slice: { items } }) => {
  const documents = (items || []).map(({ faq: { document } }) => document).filter(Boolean);
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    'mainEntity': documents.map(({ data }) => ({
      '@type': 'Question',
      'name': data.question.text,
      'acceptedAnswer': {
        '@type': 'Answer',
        'text': data.answer.text,
      },
    })),
  };
  const { siteSettings: { data: site } } = useStaticQuery(
    graphql`
      query {
        siteSettings : prismicSitesettings {
          data {
            faq_title
            faq_button_text
            faq_button_link
          }
        }
      }
    `
  );
  return (
    <>
      <Helmet>
         <script type="application/ld+json">
           {JSON.stringify(schema)}
         </script>
      </Helmet>
      <div className={classNames('xl:py-32 md:py-24 py-16 relative', className)}>
        <div className="max-w-7xl mx-auto flex lg:flex-row flex-col">
          <div className="lg:w-1/4 w-full px-4">
            <h2 className="lg:text-8xl sm:text-6xl text-4xl text-blue font-medium tracking-tighter mb-12">
              {site.faq_title}
            </h2>
          </div>
          <div className="lg:w-3/4 w-full px-4">
            <dl>
              {documents.map(({ data }) => (
                <Disclosure as="div" key={data.question.text}>
                  {({ open }) => (
                    <div className="border-b border-solid border-blue-900 pb-6 mb-6">
                      <dt className="sm:text-xl text-lg">
                        <Disclosure.Button className="font-inter text-left w-full flex justify-between items-start">
                          <h3 className="font-normal text-blue text-xl">{data.question.text}</h3>
                          <span className="ml-6 h-7 flex items-center">
                          <ArrowDownIcon
                            aria-hidden="true"
                            className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                            stroke="#00036b"
                          />
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-4 pr-12 mb-0">
                        <p className="text-base text-blue mb-0">{data.answer.text}</p>
                      </Disclosure.Panel>
                    </div>
                  )}
                </Disclosure>
              ))}
            </dl>
            <div className="flex md:justify-end">
              <Button2 button_link={site.faq_button_link}>{site.faq_button_text}</Button2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqSection;
