import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import Slider from 'react-slick';

import { cls } from '../../frontend-shared/helpers';
import { FormButton } from '../../frontend-shared/components';

import Link from './Link';

import './TrustedBrands.css';

const minCols = 8;

const settings = ({ length }) => ({
  arrows: false,
  autoplay: true,
  autoplaySpeed: 1,
  centerMode: false,
  cssEase: 'linear',
  dots: false,
  infinite: true,
  lazyLoad: true,
  pauseOnHover: false,
  rows: length >= 2 * minCols ? 2 : 1,
  slidesPerRow: Math.min(length, minCols),
  slidesToScroll: 1,
  speed: 36000,
  variableWidth: true,
  vertical: false,
  responsive: [{
    breakpoint: 1279,
    settings: {
      slidesPerRow: 7,
    },
  }, {
    breakpoint: 1024,
    settings: {
      slidesPerRow: 6,
    },
  }, {
    breakpoint: 767,
    settings: {
      slidesPerRow: 5,
    },
  }, {
    breakpoint: 640,
    settings: {
      slidesPerRow: 4,
    },
  }, {
    breakpoint: 540,
    settings: {
      slidesPerRow: 3,
    },
  }, {
    breakpoint: 480,
    settings: {
      slidesPerRow: 2,
    },
  }, {
    breakpoint: 360,
    settings: {
      slidesPerRow: 1,
    },
  }],
});

const TrustedBrands = ({
  button_text,
  button_link,
  className = 'pb-40 sm:pb-20 pt-40 md:pt-24',
  theme = 'dark',
}) => {
  const renderClient = ({ name, logo }, index) => (
    <div className="px-4 py-8 h-full w-full">
      <div className="flex flex-row items-center justify-center h-full w-full">
        <img
          alt={name}
          className="max-h-[45px] md:max-h-[3vw]"
          loading="lazy"
          key={name}
          src={logo?.fluid?.srcWebp}
          srcset={logo?.fluid?.srcSetWebp}
        />
      </div>
    </div>
  );
  const { clients, siteSettings: { data: site } } = useStaticQuery(graphql`
    query {
      siteSettings : prismicSitesettings {
        data {
          brand_carousel_title
        }
      }
      clients : allPrismicBrand(filter: { data: { relation: { eq: "client" } } }, sort: { fields: data___rank, order: DESC }) {
        edges {
          node {
            uid
            data {
              name
              logo_light {
                dimensions {
                  width
                  height
                }
                fluid {
                  srcSetWebp
                  srcWebp
                }
              }
              logo_dark {
                dimensions {
                  width
                  height
                }
                fluid {
                  srcSetWebp
                  srcWebp
                }
              }
            }
          }
        }
      }
    }
  `);
  const entries = clients.edges.map(({ node: { uid, data }, }) => ({
    uid,
    ...data,
    logo: theme === 'dark' ? data.logo_light : data.logo_dark,
  }));
  return (
    <div className={cls('TrustedBrands', { theme }, className)}>
      <h2 className="font1 text-lg md:mb-12 mb-8">
        {site.brand_carousel_title}
      </h2>
      <div className={cls('TrustedBrands-animationContainer', {}, 'flex flex-col')}>
        <Slider {...settings(entries)} className="w-full">
          {entries.slice(0, entries.length - entries.length % 2).map(renderClient)}
        </Slider>
      </div>
      {button_text && (
        <div className="flex flex-col items-center mt-8 pl-4 md:pl-0">
          <Link className="no-underline" color={theme === 'dark' ? 'primary' : 'secondary'} href={button_link}>
            <FormButton size="md">
              {button_text}
            </FormButton>
          </Link>
        </div>
      )}
    </div>
  );
};

export default TrustedBrands;
